<template>
  <div>
    <div class="overflow-x-auto border border-gray-200 rounded-xl">
      <table class="min-w-full table-fixed bg-white">
        <thead class="border-b">
          <tr>
            <th
              v-for="(header, index) in displayedHeaders"
              :key="index"
              class="h5 py-3 px-5 border-b border-gray-200 min-w-60 capitalize cursor-pointer hover:bg-neutral-0"
              @click="sortBy(header.key)"
            >
              <div class="flex items-center">
                {{ formatHeader(header.value) }}
                <DropdownArrow
                  class="ml-2"
                  :direction="getSortDirection(header.key)"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, rowIndex) in paginatedData"
            :key="rowIndex"
            class="border-b odd:bg-white even:bg-neutral-0"
          >
            <td
              v-for="(header, colIndex) in displayedHeaders"
              :key="colIndex"
              class="py-5 px-5 border-b border-gray-200 truncate max-w-42"
            >
              {{ item[header.key] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <TablePagination
      class="py-3"
      :limit="rowsPerPage"
      :page="currentPage"
      :max="totalRows"
      :disable-next="isNextDisabled"
      :disable-prev="isPrevDisabled"
      @onChange="changePage"
    />
  </div>
</template>

<script>
import TablePagination from '@/components/table/TablePagination'
import DropdownArrow from '@/components/icon/brightbid/dropdown_arrow'

export default {
  name: 'SimpleTable',
  components: {
    TablePagination,
    DropdownArrow,
  },
  props: {
    tableData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      rowsPerPage: 6,
      currentPage: 1,
      sortedColumn: null,
      sortDirection: 'asc',
    }
  },
  computed: {
    displayedHeaders() {
      const { meta_data } = this.tableData
      return meta_data && meta_data.main_header ? this.mapHeaders(meta_data.main_header) : this.defaultHeaders
    },
    defaultHeaders() {
      const headersSet = new Set()
      this.tableData.data?.forEach(item => Object.keys(item).forEach(key => headersSet.add(key)))
      return Array.from(headersSet).map(key => ({
        key,
        value: key,
      }))
    },
    paginatedData() {
      return this.paginateData(this.sortedTableData())
    },
    totalRows() {
      return this.tableData.data?.length || 0
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.rowsPerPage)
    },
    isNextDisabled() {
      return this.currentPage >= this.totalPages
    },
    isPrevDisabled() {
      return this.currentPage <= 1
    },
  },
  methods: {
    mapHeaders(headers) {
      return Object.keys(headers).map(key => ({
        key,
        value: headers[key],
      }))
    },
    changePage({ current_page }) {
      this.currentPage = current_page
    },
    sortBy(header) {
      this.sortedColumn = header
      this.sortDirection = this.sortedColumn === header && this.sortDirection === 'asc' ? 'desc' : 'asc'
    },
    sortedTableData() {
      const { data, meta_data } = this.tableData
      if (!data || !this.sortedColumn) return data || []
      const isNumeric = meta_data?.numeric_sortable_columns?.includes(this.sortedColumn)
      return this.sortData([...data], isNumeric)
    },
    sortData(data, isNumeric) {
      return data.sort((a, b) => {
        let valA = a[this.sortedColumn]
        let valB = b[this.sortedColumn]

        if (valA === undefined || valB === undefined) return 0

        // Check if the column is numeric
        if (typeof valA === 'number' && typeof valB === 'number') {
          return this.sortDirection === 'asc' ? valA - valB : valB - valA
        }

        // If it's not numeric, use string sorting
        return isNumeric ? this.sortNumeric(valA, valB) : this.sortString(valA, valB)
      })
    },
    sortNumeric(valA, valB) {
      // Ensure values are parsed correctly as numbers
      const numA = typeof valA === 'number' ? valA : parseFloat(valA.replace(/[^\d.-]/g, ''))
      const numB = typeof valB === 'number' ? valB : parseFloat(valB.replace(/[^\d.-]/g, ''))

      if (isNaN(numA) || isNaN(numB)) return 0

      return this.sortDirection === 'asc' ? numA - numB : numB - numA
    },
    sortString(valA, valB) {
      // Ensure both values are strings before applying localeCompare
      valA = valA.toString()
      valB = valB.toString()
      return this.sortDirection === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA)
    },
    getSortDirection(headerKey) {
      return this.sortedColumn === headerKey ? (this.sortDirection === 'asc' ? 'up' : 'down') : 'right'
    },
    paginateData(data) {
      const start = (this.currentPage - 1) * this.rowsPerPage
      return data.slice(start, start + this.rowsPerPage)
    },
    formatHeader(header) {
      return header
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/[-_]/g, ' ')
        .trim()
    },
  },
}
</script>
