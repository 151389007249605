<template>
  <div
    key="subCheck"
    v-click-outside="handleClose"
    class="max-h-screen overflow-y-auto mx-auto"
  >
    <div class="flex flex-row justify-items-start pt-7 mx-10">
      <pill :color="'lightGray'">
        <template #text>
          <p class="capitalize">{{ subCheck.category }}</p>
        </template>
      </pill>
    </div>
    <div class="flex flex-row justify-items-start mx-10 items-center border-b py-5">
      <h4 class="h4">{{ subCheck.title }}</h4>
    </div>
    <div
      v-if="isLoading"
      class="my-40 flex justify-center"
    >
      <BrightbidLoader />
    </div>
    <div v-if="subCheck && !isLoading">
      <div
        v-if="subCheck.severity !== 'passed'"
        key="notRemovedNotRestored"
        class="mx-10"
      >
        <div class="flex flex-col gap-y-2 items-start sm:flex-row justify-between gap-x-5 mt-4 sm:items-center">
          <div class="flex items-center">
            <Pill :color="severityItems.pillColor">
              <template #text>
                <div class="flex items-center">
                  <component
                    :is="severityItems.severityIcon"
                    :class="severityItems.severityIconStyle"
                    class="mx-auto"
                  />
                  <p
                    class="h5 font-bold mx-1"
                    :class="severityItems.failedSubChecksStyle"
                  >
                    {{ subCheck.issues_count }}
                  </p>
                  <p class="p2 font-medium pl-1 capitalize">{{ severityItems.severityText }}</p>
                </div>
              </template>
            </Pill>
          </div>
          <p class="font-medium text-bb-text-secondary">Audited on : {{ formattedCreatedAtDate }}</p>
        </div>
        <div class="max-h-100 overflow-y-auto mt-7">
          <div>
            <p
              class="p1"
              v-html="formattedSubtitle"
            ></p>
          </div>
          <div
            v-if="subCheck.recommendation"
            class="w-full bg-purple-0 rounded-2xl px-3 sm:px-6 py-3 sm:py-4 mt-7"
          >
            <div class="flex flex-row items-start">
              <div class="bg-white rounded-lg p-2 flex shadow-drop-12 mr-3 sm:mr-5">
                <ic-light-bulb-on-outline class="text-bb-brand-purple" />
              </div>
              <div class="flex flex-col gap-y-1">
                <p v-html="formattedRecommendation"></p>
                <a
                  class="font-bold text-bb-brand-purple flex flex-row items-center"
                  target="_blank"
                  href="https://ads.google.com/aw/overview"
                  >Go to Google Ads
                  <ic-chevron
                    direction="right"
                    class="ml-1 w-4 h-4"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <SimpleTable :table-data="subCheck.result" />
          </div>
        </div>
      </div>
      <div
        v-if="subCheck.severity === 'passed'"
        class="mx-10"
      >
        <div class="flex justify-end mt-4 items-center">
          <p class="font-medium text-bb-text-secondary">Audited on : {{ formattedCreatedAtDate }}</p>
        </div>
        <div class="bg-neutral-0 rounded-lg mt-3 flex flex-col justify-center items-center">
          <img
            src="/content/images/brightbid/robot-check.svg"
            alt=""
            class="w-52 h-44 pt-5"
          />
          <p
            class="py-4 xs:px-4 xs:text-center"
            v-html="formattedSubtitle"
          ></p>
        </div>
      </div>
      <div class="justify-center flex">
        <merge-button-round
          button-type="secondary"
          :disabled="false"
          class="h-9.5 w-87 my-6"
          @click="handleClose"
          >Close</merge-button-round
        >
      </div>
    </div>
  </div>
</template>

<script>
import Pill from '@/components/input/Pill.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import IcClock from '@/components/icon/brightbid/IcClock.vue'
import IcAlertOutline from 'vue-material-design-icons/AlertOutline'
import IcMinusCircleOutline from 'vue-material-design-icons/MinusCircleOutline'
import IcLightBulbOutline from 'vue-material-design-icons/LightbulbOutline'
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcReplay from 'vue-material-design-icons/Replay'
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import IcLightBulbOnOutline from 'vue-material-design-icons/LightbulbOnOutline'
import SimpleTable from '@/components/table/SimpleTable.vue'
import Toast from '@/components/shared/Toast.vue'
import BrightbidLoader from '../loader/BrightbidLoader.vue'
import IcCheckCircleOutline from 'vue-material-design-icons/CheckCircleOutline'
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AuditReportModal',
  components: {
    Pill,
    MergeButtonRound,
    IcClock,
    IcAlertOutline,
    IcChevron,
    IcAlertCircle,
    IcMinusCircleOutline,
    IcCheckCircleOutline,
    IcLightBulbOutline,
    IcLightBulbOnOutline,
    dayjs,
    IcReplay,
    SimpleTable,
    Toast,
    BrightbidLoader,
  },
  props: {
    identification: {
      type: Number,
      required: true,
    },
    checkId: {
      type: String,
      required: true,
    },
    auditId: {
      type: String,
      required: true,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRemoved: false,
      isRestored: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('auditCheck', { subCheck: 'getCheckDetails', checkError: 'getError' }),
    severityItems() {
      const severityMap = {
        warning: {
          pillColor: 'warningYellow',
          failedSubChecksStyle: 'text-bb-warning',
          severityIcon: 'IcAlertCircle',
          severityIconStyle: 'text-bb-warning',
          severityText: 'Warnings',
        },
        urgent: {
          pillColor: 'errorRed',
          failedSubChecksStyle: 'text-bb-red-error',
          severityIcon: 'IcAlertOutline',
          severityIconStyle: 'text-bb-red-error',
          severityText: 'Urgent Issues',
        },
        optimization: {
          pillColor: 'purple',
          failedSubChecksStyle: 'text-bb-brand-purple',
          severityIcon: 'IcLightBulbOutline',
          severityIconStyle: 'text-bb-brand-purple',
          severityText: 'Optimizations',
        },
        undefined: {
          pillColor: 'gray',
          failedSubChecksStyle: 'text-bb-grey',
          severityIcon: 'IcAlertCircle',
          severityIconStyle: 'text-bb-grey',
          severityText: 'Undefined',
        },
      }
      return severityMap[this.subCheck.severity] || severityMap.undefined
    },
    formattedCreatedAtDate() {
      return this.subCheck ? dayjs(this.subCheck.created_at).format('DD/MM/YYYY') : ''
    },
    formattedRecommendation() {
      // Check if `subCheck.recommendation` exists before using it
      return this.subCheck && this.subCheck.recommendation ? this.subCheck.recommendation.replace(/\n/g, '<br>') : ''
    },
    formattedSubtitle() {
      // Check if `subCheck.subtitle` exists before using it
      return this.subCheck && this.subCheck.subtitle ? this.subCheck.subtitle.replace(/\n/g, '<br>') : ''
    },
  },

  mounted() {
    this.fetchAuditCheckData()
  },
  methods: {
    ...mapActions('auditCheck', ['fetchCheckDetails', 'fetchExternalCheckDetails']),

    handleClose() {
      this.$emit('close')
    },
    async fetchAuditCheckData() {
      this.isLoading = true
      // Choose the appropriate action based on `isExternal`
      if (this.isExternal) {
        await this.fetchExternalCheckDetails({
          customerId: this.identification,
          reportId: this.auditId,
          checkId: this.checkId,
        })
      } else {
        await this.fetchCheckDetails({
          siteId: this.identification,
          reportId: this.auditId,
          checkId: this.checkId,
        })
      }
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
.modal-container {
  z-index: 9000;
}
</style>
