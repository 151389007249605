<template>
  <div class="p-4 flex flex-col gap-4">
    <div
      v-for="(checks, severity) in checksBySeverity"
      :key="severity"
      :id="severity"
    >
      <bb-base-card
        class="bg-white cursor-pointer"
        :checkbox="false"
        hover-disabled
        open-by-default
        full-click-expand
      >
        <template #first>
          <div class="flex flex-row items-center gap-2">
            <component
              :is="severityContent[severity].icon"
              class="p-1 rounded-full"
              :class="severityContent[severity].headerClass"
            />
            <p class="h4">
              <span>{{ checks.length }}</span> {{ severityContent[severity].title }}
            </p>
          </div>
        </template>
        <template #expanded-content>
          <div
            v-if="!checks.length"
            class="flex flex-row justify-center gap-4 p-4"
          >
            <img
              :src="emptyMessages[severity].img"
              alt="empty message image"
              class="h-20 w-18"
            />
            <div class="flex flex-col my-auto">
              <p class="font-medium">{{ emptyMessages[severity].title }}</p>
              <p class="font-normal">{{ emptyMessages[severity].subtitle }}</p>
            </div>
          </div>
          <div
            v-else
            class="flex flex-col rounded-lg divide-y divider-neutral-100 bg-neutral-0"
          >
            <div
              v-for="check in checks"
              :key="check.id"
              class="w-full py-7 flex flex-row justify-between items-center px-4"
              @click.stop="openModal(check.id)"
            >
              <div
                v-if="check.status === 'failed'"
                class="flex flex-row items-center gap-2 text-bb-disabled-buttons"
              >
                <ic-alert-rhombus class="p-1 rounded-full" />
                <p>{{ check.title }}</p>
              </div>
              <div
                v-else
                class="flex flex-row gap-2 items-center"
              >
                <component
                  :is="severityContent[check.severity].icon"
                  class="p-1 rounded-full"
                  :class="severityContent[check.severity].iconClass"
                />
                <p>{{ check.title }}</p>
              </div>
              <div
                v-if="check.status === 'failed'"
                class="flex flex-row text-bb-error gap-2 items-center font-medium p1"
              >
                <p>Invalid</p>
                <ic-alert-circle :size="16" />
              </div>
              <div
                v-else
                class="grid gap-2 items-center"
                :class="{
                  'grid-cols-1': readonly && check.issues_count === 0,
                  'grid-cols-2': (readonly && check.issues_count > 0) || (!readonly && check.issues_count === 0),
                  'grid-cols-3': !readonly && check.issues_count > 0,
                }"
              >
                <p
                  v-if="check.issues_count > 0"
                  class="h5 font-bold px-2 rounded-full ml-auto text-right mr-4"
                  :class="severityContent[check.severity].headerClass"
                >
                  {{ check.issues_count }}
                </p>
                <div class="w-32 bg-neutral-50 rounded-2xl items-center">
                  <p class="text-bb-neutral-gray capitalize p3 py-1 text-center font-bold">
                    {{ check.category == 'ads' ? 'Ads & Assets' : check.category }}
                  </p>
                </div>
                <merge-button-round
                  v-if="!readonly"
                  button-type="tertiary"
                  @click="openModal(check.id)"
                >
                  <p>See more</p>
                </merge-button-round>
              </div>
            </div>
          </div>
        </template>
      </bb-base-card>
    </div>
  </div>
</template>

<script>
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import IcAlertTriangle from 'vue-material-design-icons/AlertOutline'
import IcLightbulbOutline from 'vue-material-design-icons/LightbulbOutline'
import IcCheckCircleOutline from 'vue-material-design-icons/CheckCircleOutline'
import bbBaseCard from '@/components/card/bbBaseCard.vue'
import IcAlertRhombus from 'vue-material-design-icons/AlertRhombusOutline'

export default {
  name: 'AuditCheckList',
  components: {
    IcAlertCircle,
    IcAlertTriangle,
    IcLightbulbOutline,
    IcCheckCircleOutline,
    bbBaseCard,
    IcAlertRhombus,
  },
  props: {
    checks: {
      type: Array,
      required: false,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    checksBySeverity() {
      const severityOrder = ['urgent', 'warning', 'optimization', 'passed']

      // Group checks by severity
      const groupedChecks = this.checks.reduce((acc, check) => {
        if (!acc[check.severity]) {
          acc[check.severity] = []
        }
        acc[check.severity].push(check)
        return acc
      }, {})

      // Separate filled and empty severities
      const filledSeverities = {}
      const emptySeverities = {}

      severityOrder.forEach(severity => {
        if (groupedChecks[severity] && groupedChecks[severity].length) {
          filledSeverities[severity] = groupedChecks[severity]
        } else {
          emptySeverities[severity] = []
        }
      })

      // Combine filled severities first, then empty severities
      return { ...filledSeverities, ...emptySeverities }
    },
    severityContent() {
      return {
        urgent: {
          icon: 'ic-alert-triangle',
          headerClass: 'text-bb-error bg-bb-red-50',
          iconClass: 'text-bb-error',
          backgroundClass: 'bg-bb-red-50',
          title: 'Urgent Issues',
        },
        warning: {
          icon: 'ic-alert-circle',
          headerClass: 'text-bb-warning bg-bb-yellow-50',
          iconClass: 'text-bb-warning',
          backgroundClass: 'bg-bb-yellow-50',
          title: 'Warnings',
        },
        optimization: {
          icon: 'ic-lightbulb-outline',
          headerClass: 'text-bb-brand-purple bg-bb-secondary-purple',
          iconClass: 'text-bb-brand-purple',
          backgroundClass: 'bg-bb-secondary-purple',
          title: 'Optimizations',
        },
        passed: {
          icon: 'ic-check-circle-outline',
          headerClass: 'text-success bg-bb-success-bg',
          iconClass: 'text-success',
          backgroundClass: 'bg-bb-success-bg',
          title: 'Passed checks',
        },
      }
    },
    emptyMessages() {
      return {
        passed: {
          title: 'You haven’t passed any checks.',
          subtitle: 'Please take action on the suggested recommendations.',
          img: '/content/images/brightbid/robot-red-warning.svg',
        },
        optimization: {
          title: 'All Set!',
          subtitle: 'Your account is optimized.',
          img: '/content/images/brightbid/robot-bulb.svg',
        },
        warning: {
          title: 'Great!',
          subtitle: 'No warnings to display.',
          img: '/content/images/brightbid/robot-warning.svg',
        },
        urgent: {
          title: 'Great!',
          subtitle: 'No urgent issues to display.',
          img: '/content/images/brightbid/robot-urgent.svg',
        },
      }
    },
  },
  methods: {
    openModal(checkId) {
      this.$emit('openModal', checkId)
    },
    scrollToSeverity(severity) {
      this.$nextTick(() => {
        const element = document.getElementById(severity) // Check for element with ID

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        } else {
          console.error(`Element with ID ${severity} not found.`)
        }
      })
    },
  },
}
</script>
